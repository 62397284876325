/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import { Alert, Button, Popconfirm, Table, Tooltip } from 'antd';
import { MdDeleteOutline } from 'react-icons/md';
import { toast } from 'react-toastify';
import { AssociationProductContentSelect, Container } from './styles';
import { api } from '../../../services/api';
import { Loading } from '../../../components/Loading';

export function DispatcherVirtual({ productId }) {
  const [loading, setLoading] = useState(true);
  const [dispacherProducts, setDispacherProducts] = useState();
  const [productDispacherList, setProductDispacherList] = useState([]);
  const [dispacherProductToAdd, setDispacherProductToAdd] = useState();
  const [
    addDispatcherToProductErrorMessage,
    setAddDispatcherToProductErrorMessage,
  ] = useState();

  async function getDispatchersOfProduct() {
    try {
      const response = await api.get(
        `/products/virtual-dispatcher/${productId}`,
      );

      if (response.data.product) {
        setProductDispacherList([
          {
            id: response.data.product.id,
            key: '1',
            product_code: response.data.product.code,
            product_name: response.data.product.name,
          },
        ]);

        return;
      }

      setProductDispacherList([]);
    } catch (error) {
      console.log(error);
      toast.error('Erro ao carregar despachantes do produto');
    }
  }

  async function deleteDispatcherOfProduct() {
    try {
      await api.delete(`/products/virtual-dispatcher/${productId}`);
      getDispatchersOfProduct();
      setAddDispatcherToProductErrorMessage('');
    } catch (error) {
      toast.error('Erro ao carregar produtos do tipo despachante');
      console.log(error);
    }
  }

  async function addDispatcherToProduct() {
    try {
      await api.post('/products/virtual-dispatcher', {
        product_id: productId,
        dispatcher_virtual_id: dispacherProductToAdd,
      });

      getDispatchersOfProduct();
      setAddDispatcherToProductErrorMessage('');
    } catch (error) {
      toast.error('Erro ao adicionar despachante virtual ao produto');
      setAddDispatcherToProductErrorMessage(error.response.data.message);
    }
  }

  async function getDispatcherProducts() {
    try {
      setLoading(true);
      const response = await api.get(
        '/products/?product_type=VIRTUAL_DISPATCHER',
      );

      setDispacherProducts(
        response.data.products.map((product) => ({
          value: product.id,
          label: product.name,
        })),
      );
      setLoading(false);
    } catch (error) {
      toast.error('Erro ao carregar produtos do tipo despachante');
      console.log(error);
      setLoading(false);
    }
  }

  const columns = [
    {
      title: 'Código do produto',
      dataIndex: 'product_code',
      key: 'email',
    },
    {
      title: 'Nome do produto',
      dataIndex: 'product_name',
      key: 'name',
    },
    {
      title: 'Ações',
      key: 'action',
      render: () => (
        <div>
          <Popconfirm
            title="Excluir despachante virtual?"
            description="Tem certeza que deseja excluir despachante do produto  ?"
            onConfirm={deleteDispatcherOfProduct}
            okText="Confirmar"
            cancelText="Não"
          >
            <Tooltip title="Excluir despachante virtual">
              <Button
                icon={<MdDeleteOutline size={20} color="#fff" />}
                style={{ background: '#ff4d4f' }}
              />
            </Tooltip>
          </Popconfirm>
        </div>
      ),
    },
  ];

  useEffect(() => {
    getDispatcherProducts();
    getDispatchersOfProduct();
  }, []);

  console.log({
    dispacherProducts,
    dispacherProductToAdd,
    productDispacherList,
  });

  return (
    <>
      {!loading && (
        <Container>
          {addDispatcherToProductErrorMessage && (
            <Alert
              message="Erro ao atribuir despachante"
              description={addDispatcherToProductErrorMessage}
              type="error"
              showIcon
              style={{ marginBottom: 30 }}
            />
          )}

          <h3>Selecione um produto que o usuário poderá trabalhar</h3>
          <AssociationProductContentSelect>
            <Select
              options={dispacherProducts}
              onChange={(productItem) =>
                setDispacherProductToAdd(productItem.value)
              }
            />

            <Popconfirm
              title="Adicionar produto para um usuário"
              description="Tem certeza que deseja associar esse produto ?"
              onConfirm={addDispatcherToProduct}
              okText="Confirmar"
              cancelText="Não"
            >
              <Button
                style={{
                  marginLeft: 15,
                  minWidth: 200,
                  fontSize: 14,
                }}
                size="large"
                type="primary"
              >
                Adicionar
              </Button>
            </Popconfirm>
          </AssociationProductContentSelect>

          <Table
            columns={columns}
            dataSource={productDispacherList}
            pagination={false}
          />
        </Container>
      )}

      {loading && <Loading />}
    </>
  );
}
